<template>
  <div data-cy="home">
    <header>
      <div class="logo">
        <img
          src="https://res.cloudinary.com/caarea/image/upload/v1668598032/cs/sign/caarea-logo_iwxjqz.svg"
          alt=""
        />
        <div>{{ $t("home.subscription") }}</div>
      </div>
    </header>
    <main>
      <div class="welcome">
        <div data-cy="welcome-message">
          {{ $t("home.welcome", { firstname: getBeneficiaryName }) }}
        </div>
        {{ $t("home.welcome_end") }}
      </div>
      <p>{{ $t("home.text") }}</p>
      <router-link class="button" :to="nextRoute" data-cy="subscription-access-button">
        {{ $t("home.subscription_space") }}
      </router-link>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Home",
  computed: {
    ...mapGetters("sale", ["getBeneficiaryName", "isSubscriptionCompleted"]),
    nextRoute() {
      return this.isSubscriptionCompleted ? { name: "congrats" } : { name: "offers" }
    },
  },
  created() {
    if (this.isSubscriptionCompleted) {
      this.$store.dispatch(
        "gui/setCongratsInvitationText",
        this.$t("congrats.invitation_my_caarea_already_sent")
      )
    }
  },
}
</script>

<style lang="scss" scoped>
header {
  padding-top: 72px;
  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin: auto;
    background: $caareamedium;
    border-radius: 100%;
    width: 284px;
    height: 284px;
    div {
      color: $caarealightblue;
      font-size: rem-calc(18);
      font-weight: 500;
      letter-spacing: 0.09rem;
      line-height: 22px;
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 16px;
  .welcome {
    font-family: $font-family-alt;
    font-size: rem-calc(12);
    font-weight: 300;
    letter-spacing: 0.09rem;
    line-height: 14px;
    text-align: center;
    div {
      margin-bottom: 8px;
      font-family: $font-family-base;
      font-size: rem-calc(18);
      font-weight: 600;
      letter-spacing: 0.09rem;
      line-height: 22px;
    }
  }
  p {
    padding: 0 30px;
    font-weight: 300;
    font-size: rem-calc(11);
    letter-spacing: 0.09rem;
    line-height: 20px;
    text-align: justify;
  }
}
</style>
