<template>
  <div>
    <img
      src="https://res.cloudinary.com/caarea/image/upload/v1617955698/cs/assets/404_qktbda.png"
      alt="404 not found"
    />
    <b>{{ $t("error.404") }}</b>
    <p v-if="message" class="error">{{ message }}</p>
    <p>{{ $t("error.continue") }}</p>
    <button data-cy="home-btn" @click.prevent="onClick">
      {{ $t("error.home") }}
    </button>
  </div>
</template>
<script>
import ErrorPageMixin from "@/mixins/ErrorPageMixin"

export default {
  name: "Page404",
  mixins: [ErrorPageMixin],
  props: { message: { type: String, default: null } },
  methods: {
    onClick() {
      this.pushI18nRoute({ name: this.getUrlName })
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
