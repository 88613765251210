import store from "@/store"

export default {
  getI18nDefaultRoute(route) {
    const lang = store.getters["config/getCurrentLang"]
    return { path: `/${lang}${route.path}` }
  },

  getI18nRoute(route) {
    if (!route.params || !route.params.hasOwnProperty("lang")) {
      if (!route.params) {
        route["params"] = {}
      }
      route.params["lang"] = store.getters["config/getCurrentLang"]
    }
    return route
  },
}
