<template>
  <div class="page-resume">
    <div class="card">
      {{ $t("resume.monthly_subscriptions") }}
      <OffersList :offers="monthlyOffers" />
    </div>
    <div class="card">
      {{ $t("resume.cash_payment") }}
      <OffersList :offers="cashOffers" />
    </div>
    <div class="action-buttons">
      <router-link class="button" :to="{ name: 'payment' }">
        {{ $t("resume.payment") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OffersList from "@/components/OffersList"

export default {
  name: "Resume",
  components: {
    OffersList,
  },
  computed: {
    ...mapGetters("offers", ["getOffers"]),
    monthlyOffers() {
      return this.getOffers.filter((offer) => offer.monthly)
    },
    cashOffers() {
      return this.getOffers.filter((offer) => !offer.monthly)
    },
  },
}
</script>

<style lang="scss" scoped>
.page-resume {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 -8px;
  .card {
    padding-top: 12px;
    padding-bottom: 12px;
    .offers-list {
      margin: 24px -8px 0;
    }
  }
}
</style>
