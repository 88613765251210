import store from "@/store/index"
import i18n from "@/i18n/index"

function getI18nDefaultRoute(route) {
  const lang = store.getters["config/getCurrentLang"]
  return { path: `/${lang}${route.path}` }
}

function getI18nRoute(route) {
  if (!route.params || !route.params.hasOwnProperty("lang")) {
    if (!route.params) {
      route["params"] = {}
    }
    route.params["lang"] = store.getters["config/getCurrentLang"]
  }
  return route
}

const setLanguage = (to, from, next) => {
  const lang = to.params.hasOwnProperty("lang") ? to.params.lang : false

  if (lang && store.getters["config/isLangAvailable"](lang) === false) {
    // console.log("lang unknown", lang, "add default one")
    return next(getI18nDefaultRoute(to))
  }

  if (lang) {
    if (lang === store.getters["config/getCurrentLang"]) {
      // console.log("lang ok")
      if (to.name !== undefined) {
        // console.log("continue", to)
        return next()
      }
      // console.log("redirect 404", getI18nRoute({ name: "404" }))
      return next(getI18nRoute({ name: "404" }))
    }

    if (i18n.messages.hasOwnProperty(lang)) {
      // console.log("lang update requested", lang)
      // requested lang update
      store.dispatch("config/setCurrentLang", lang)
      return next()
    }
  }
  // lang unknown or missing set default one
  if (lang) {
    // console.log("lang is unknown", to)
    to.params.lang = store.getters["config/getCurrentLang"]
    // console.log("add default lang", to)
    next(to)
  } else {
    // console.log(
    //   "lang is missing, add it",
    //   `/${store.getters["config/getCurrentLang"]}${to.path}`
    // )
    // next({ path: `/${store.getters["config/getCurrentLang"]}${to.path}` })
    next(getI18nDefaultRoute(to))
  }
}

const beforeEnter = (to, from, next) => {
  setLanguage(to, from, next)
}

const requireAccess = async (to, from, next) => {
  const token = to.params.hasOwnProperty("token") ? to.params.token : false
  try {
    await store.dispatch("sale/retrieveSale", token)
    next()
  } catch (e) {
    if (e && e.status === 404) {
      next(getI18nRoute({ name: "404" }))
    } else if (e && e.status === 403) {
      next(getI18nRoute({ name: "403" }))
    } else if (e && e.status === 500) {
      next(getI18nRoute({ name: "500" }))
    }
  }
}

export default {
  requireAccess,
  beforeEnter,
}
