<template>
  <div class="mainlayout">
    <header>
      <img
        src="https://res.cloudinary.com/caarea/image/upload/v1668598032/cs/sign/caarea-logo_iwxjqz.svg"
        alt=""
      />
      <div data-cy="header-custom-text" class="welcome">
        <div>{{ $t("offers.welcome", { firstname: getBeneficiaryName }) }}</div>
        {{ $t("offers.sign_contract_one_click") }}
      </div>
    </header>
    <main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Layout",
  computed: {
    ...mapGetters("sale", ["getBeneficiaryName"]),
  },
}
</script>

<style lang="scss" scoped>
header {
  padding: 32px;
  background: $caareamedium;
  text-align: center;
  min-height: 205px;
  img {
    width: 85px;
  }
  .welcome {
    margin-top: 32px;
    color: $caarealightblue;
    font-family: $font-effra-regular;
    font-size: rem-calc(13);
    letter-spacing: 0.09rem;
    line-height: 16px;
    div {
      margin-bottom: 8px;
      font-family: $font-family-base;
      font-size: rem-calc(18);
      font-weight: 300;
      letter-spacing: 0.09rem;
      line-height: 22px;
    }
  }
}
</style>
