<template>
  <ModalBottom @close="onClose">
    <template #body>
      <div class="modal-terms">
        <i18n path="offers.terms_modal.read_the_terms" tag="div" class="terms-read">
          <template #terms>
            <router-link :to="{ name: 'terms' }">
              {{ $t("offers.terms_modal.terms") }}
            </router-link>
          </template>
        </i18n>
        <router-link
          class="button terms-accept"
          :to="{ name: 'steps' }"
          data-cy="terms-acceptance-button"
        >
          {{ $t("offers.terms_modal.accept_the_terms") }}
        </router-link>
        <a class="terms-refuse" @click="onClose">
          {{ $t("offers.terms_modal.refuse_the_terms") }}
        </a>
      </div>
    </template>
  </ModalBottom>
</template>

<script>
import ModalBottom from "@/components/modal/ModalBottom"

export default {
  name: "ModalTerms",
  components: {
    ModalBottom,
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-terms {
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
}
.terms-read {
  font-family: $font-effra-regular;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.09rem;
  a {
    color: inherit;
    font-family: $font-effra-semibold;
    font-size: rem-calc(18);
  }
}
</style>
