import AbstractService from "@/services/AbstractService"
import axios from "axios"

class HttpError extends Error {
  constructor(message, response) {
    super(message)
    this.status = response.status
    this.data = response.data ? response.data : response.statusText
    this.response = response
  }
}

class Http extends AbstractService {
  /**
   * get data from url
   * @param url
   * @param accepted_content_type
   * @param timeout
   * @returns {Promise<unknown>}
   */
  get(url, accepted_content_type = null, timeout = null) {
    return new Promise(async (successCallback, failureCallback) => {
      let options = {}
      if (accepted_content_type) {
        options.headers = {
          Accept: accepted_content_type,
        }
        // Set response type as ArrayBuffer so that response.data can be converted into blob
        // Note : this part of code is not covered as we don't know how to test it
        if (accepted_content_type.includes("application/pdf")) {
          options.responseType = "arraybuffer"
        }
      }
      if (timeout) {
        options.timeout = timeout
      }
      try {
        const response = await axios.get(url, options)
        successCallback(response.data)
      } catch (e) {
        failureCallback(e.response ? new HttpError(e, e.response) : e)
      }
    })
  }

  /**
   * post data to url
   * @param url
   * @param data
   * @param options
   * @returns {Promise<unknown>}
   */
  post(url, data, options = {}) {
    return new Promise(async (successCallback, failureCallback) => {
      try {
        const response = await axios.post(url, data, options)
        successCallback(response.data)
      } catch (e) {
        failureCallback(e.response ? new HttpError(e, e.response) : e)
      }
    })
  }

  /**
   * put data to url
   * @param url
   * @param data
   * @returns {Promise<unknown>}
   */
  put(url, data) {
    return new Promise(async (successCallback, failureCallback) => {
      try {
        const response = await axios.put(url, data)
        successCallback(response.data)
      } catch (e) {
        failureCallback(e.response ? new HttpError(e, e.response) : e)
      }
    })
  }

  /**
   * delete data to url
   * @param url
   * @param data
   * @returns {Promise<unknown>}
   */
  delete(url, data) {
    return new Promise(async (successCallback, failureCallback) => {
      try {
        const response = await axios.delete(url, data)
        successCallback(response.data)
      } catch (e) {
        failureCallback(e.response ? new HttpError(e, e.response) : e)
      }
    })
  }
}

let HttpService = new Http()
export { HttpService as default, HttpError }
