// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

const state = () => ({
  sale: null,
})

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_SALE(state, sale) {
    state.sale = sale
  },
}

// ACTIONS (camelCase)
const actions = {
  async retrieveSale({ commit }, token) {
    try {
      let sale = await HttpService.get(
        UrlService.render("sale", { id: token }, { by_token: true })
      )
      commit("SET_SALE", sale)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async createContract({ state, commit }, signatureRequestId) {
    try {
      const sale = await HttpService.post(
        UrlService.render("saleContract", { id: state.sale.token }),
        { signature_request_id: signatureRequestId }
      )
      commit("SET_SALE", sale)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async sendConfirmationEmail({ state, commit }, token) {
    try {
      await HttpService.post(
        UrlService.render("sendConfirmationEmail", { id: token }, { by_token: true }),
        {}
      )
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getSale: (state) => state.sale,
  getBeneficiaryName: (state) => state.sale.beneficiary.user.first_name,
  getOffers: (state) => state.sale.offers,
  getOfferContent: (state) => (OfferId) => {
    return state.sale.offers
      .find((offer) => offer.id === OfferId)
      .contents.find((offerContent) => offerContent.type === "B2C")
  },
  getSignatureLink: (state) => state.sale.signature_link,
  isContractSigned: (state) =>
    state.sale.current_status === "CONTRACT_SIGNED" ||
    state.sale.current_status === "SUBSCRIBED",
  isSubscriptionCompleted: (state) => state.sale.current_status === "SUBSCRIBED",
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
