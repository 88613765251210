<template>
  <div class="modal modal-bottom" @click="onClose">
    <div class="modal-content" @click.stop>
      <div class="modal-body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalBottom",
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-bottom {
  align-items: flex-end;
  .modal-content {
    margin: 0 auto;
    border-bottom-right-radius: initial;
    border-bottom-left-radius: initial;
  }
}
</style>
