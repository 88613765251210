<template>
  <Modal @close="onClose">
    <template #title>{{ $t("offers.signature_info_modal.title") }}</template>
    <template #body>
      <div class="modal-signature">
        <p class="signature-text">{{ $t("offers.signature_info_modal.text") }}</p>
        <div class="signature-process" data-cy="signature-process">
          {{ $t("offers.signature_info_modal.process") }}
        </div>
        <ul class="signature-steps">
          <li v-for="stepNb in numberOfSteps" :key="stepNb">
            <div>{{ stepNb }}</div>
            <div :data-cy="`signature-info-step-${stepNb}`">
              {{ $t(`offers.signature_info_modal.step_${stepNb}`) }}
            </div>
          </li>
        </ul>
        <button @click="onClose" data-cy="understood-button">
          {{ $t("offers.signature_info_modal.understood") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal"

export default {
  name: "ModalSignature",
  components: {
    Modal,
  },
  data() {
    return {
      numberOfSteps: 5,
    }
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-signature {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
  .signature-text {
    margin: 0;
    font-size: rem-calc(11);
    font-weight: 300;
    letter-spacing: 0.09rem;
    line-height: 20px;
    text-align: justify;
  }
  .signature-process {
    font-size: rem-calc(12);
    font-weight: 600;
    letter-spacing: 0.09rem;
    line-height: 30px;
  }
  .signature-steps {
    display: flex;
    flex-direction: column;
    gap: 20px;
    li {
      display: flex;
      gap: 8px;
      font-size: rem-calc(12);
      letter-spacing: 0.09rem;
      line-height: 20px;
      text-align: justify;
      div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background: $bleucaarea;
        border-radius: 100%;
        color: #fff;
        font-family: $font-family-alt;
        font-size: rem-calc(14);
        width: 20px;
        height: 20px;
      }
    }
  }
  .signature-actions {
    margin-top: 16px;
  }
}
</style>
