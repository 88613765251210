import Vue from "vue"
import Vuex from "vuex"
import config from "@/store/config"
import sale from "@/store/sale"
import offers from "@/store/offers"
import gui from "@/store/gui"
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    config,
    sale,
    offers,
    gui,
  },
})
