<template>
  <div class="page-congrats">
    <div class="congrats-icon">
      <span class="material-symbols-outlined">check</span>
    </div>
    <div>
      <div class="congrats-bravo">{{ $t("congrats.bravo") }}</div>
      <div class="congrats-registered">
        {{ $t("congrats.subscription_registered") }}
      </div>
    </div>
    <div class="congrats-invitation" data-cy="congrats-invitation-text">
      {{ getCongratsInvitationText }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Congrats",
  computed: {
    ...mapGetters("gui", ["getCongratsInvitationText"]),
  },
}
</script>

<style lang="scss" scoped>
.page-congrats {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  padding: 56px 16px 0;
  text-align: center;
  .congrats-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #16825d;
    border-radius: 100%;
    width: 143px;
    height: 133px;
    span {
      color: #fff;
      font-size: rem-calc(112);
    }
  }
  .congrats-bravo,
  .congrats-registered,
  .congrats-invitation {
    font-family: $font-effra-regular;
    line-height: 25px;
    letter-spacing: 0.09rem;
  }
  .congrats-bravo {
    font-size: rem-calc(28);
  }
  .congrats-registered {
    margin-top: 8px;
  }
  .congrats-invitation {
    font-size: rem-calc(14);
  }
}
</style>
