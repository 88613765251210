import { render, staticRenderFns } from "./ModalTerms.vue?vue&type=template&id=129c7144&scoped=true&"
import script from "./ModalTerms.vue?vue&type=script&lang=js&"
export * from "./ModalTerms.vue?vue&type=script&lang=js&"
import style0 from "./ModalTerms.vue?vue&type=style&index=0&id=129c7144&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "129c7144",
  null
  
)

export default component.exports