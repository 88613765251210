<template>
  <router-view data-cy="app" />
</template>
<script>
export default {
  name: "App",
}
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Quicksand:400,500,600&display=swap");
</style>
