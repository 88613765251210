<template>
  <div data-cy="offers">
    <transition name="slide" mode="out-in">
      <ModalTerms
        v-if="isModalTermsOpen"
        @close="toggleModalTerms"
        data-cy="terms-modal"
      />
    </transition>
    <transition name="fade" mode="out-in">
      <ModalSignature
        v-if="isModalSignatureOpen"
        @close="toggleModalSignature"
        data-cy="signature-info-modal"
      />
    </transition>
    <div class="offers-list" :class="nbOffers">
      <div
        class="offer"
        v-for="offer in getOffers"
        :key="offer.id"
        :data-cy="`offer-${offer.id}`"
      >
        <img :src="getOfferContent(offer.id).image" alt="" />
        <div class="offer-name">{{ getOfferContent(offer.id).name }}</div>
      </div>
    </div>
    <div class="action-buttons">
      <button @click.prevent="toggleModalTerms" data-cy="contract-action-button">
        {{ buttonText }}
      </button>
      <div class="link-icon">
        <span class="material-symbols-outlined">info</span>
        <a @click.prevent="toggleModalSignature" data-cy="signature-info-link">
          {{ $t("offers.signature_information") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ModalTerms from "@/components/modal/ModalTerms"
import ModalSignature from "@/components/modal/ModalSignature"

export default {
  name: "Offers",
  components: {
    ModalTerms,
    ModalSignature,
  },
  data() {
    return {
      isModalTermsOpen: false,
      isModalSignatureOpen: false,
    }
  },
  computed: {
    ...mapGetters("sale", ["getOffers", "getOfferContent", "isContractSigned"]),
    nbOffers() {
      return (
        "offers-" +
        (this.getOffers.length === 1
          ? "one"
          : this.getOffers.length === 2
          ? "two"
          : "more")
      )
    },
    buttonText() {
      return this.isContractSigned
        ? this.$t("offers.finalize_contract")
        : this.$t("offers.sign_contract")
    },
  },
  methods: {
    toggleModalTerms() {
      this.isModalTermsOpen = !this.isModalTermsOpen
    },
    toggleModalSignature() {
      this.isModalSignatureOpen = !this.isModalSignatureOpen
    },
  },
}
</script>

<style lang="scss" scoped>
.offers-list {
  display: grid;
  gap: 12px;
  &.offers-one {
    padding: 16px 36px 24px;
    img {
      padding-bottom: 44px;
    }
  }
  &.offers-two {
    grid-template-rows: 1fr 1fr;
    padding: 16px 64px 24px;
    .offer {
      .offer-name {
        padding: 8px;
        min-height: 33px;
      }
    }
    img {
      padding-bottom: 33px;
    }
  }
  &.offers-more {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 36px;
    padding: 48px 16px 72px;
    .offer {
      .offer-name {
        padding: 5px;
        font-size: rem-calc(8);
        line-height: 10px;
        min-height: 21px;
      }
    }
    img {
      padding-bottom: 20px;
    }
  }
  .offer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 6px;
    box-shadow: $shadow;
    overflow: hidden;
    img {
      display: block;
      position: relative;
      width: 100%;
    }
    .offer-name {
      position: absolute;
      bottom: 0;
      padding: 12px;
      background: $gray7;
      font-size: rem-calc(13);
      letter-spacing: 0.09em;
      line-height: 16px;
      text-align: center;
      width: 100%;
      min-height: 44px;
    }
  }
}
</style>
