<template>
  <div class="modal" @click="onClose">
    <div class="modal-content" @click.stop>
      <div class="modal-header" data-cy="modal-title">
        <slot name="title"></slot>
      </div>
      <div class="modal-body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-content .modal-body {
  padding: 16px 32px 32px;
}
</style>
