import i18n from "../i18n"

// STATES (snake_case)
const state = {
  congrats_invitation_text: i18n.t("congrats.invitation_my_caarea_to_be_sent"),
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_CONGRATS_INVITATION_TEXT(state, translatedText) {
    state.congrats_invitation_text = translatedText
  },
}

// ACTIONS (camelCase)
const actions = {
  setCongratsInvitationText({ commit }, translatedText) {
    commit("SET_CONGRATS_INVITATION_TEXT", translatedText)
  },
}

// GETTERS (camelCase)
const getters = {
  getCongratsInvitationText: (state) => state.congrats_invitation_text,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
