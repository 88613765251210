<template>
  <div class="page-payment">
    <div class="card">
      <OffersList :offers="monthlyOffers" />
    </div>
    <div class="card">
      <OffersList :offers="cashOffers" />
    </div>
    <div class="card">
      {{ $t("payment.payment_page") }}
      <div class="payment-infos">
        <div class="number">
          <input
            type="text"
            v-mask="'#### #### #### ####'"
            :placeholder="$t('payment.credit_card_number')"
          />
        </div>
        <div class="date-code">
          <input
            class="payment-date"
            type="text"
            v-mask="'##/##'"
            :placeholder="$t('payment.expiration_date')"
          />
          <input
            class="payment-code"
            type="text"
            v-mask="'###'"
            :placeholder="$t('payment.card_validation_code')"
          />
        </div>
      </div>
    </div>
    <div class="action-buttons">
      <div class="button" data-cy="pay-btn" @click="onPayBtnClick">
        {{ $t("payment.pay") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OffersList from "@/components/OffersList"

export default {
  name: "Payment",
  components: {
    OffersList,
  },
  computed: {
    ...mapGetters("offers", ["getOffers"]),
    ...mapGetters("sale", ["getSale"]),
    monthlyOffers() {
      return this.getOffers.filter((offer) => offer.monthly)
    },
    cashOffers() {
      return this.getOffers.filter((offer) => !offer.monthly)
    },
  },
  methods: {
    async onPayBtnClick() {
      await this.$store.dispatch("sale/sendConfirmationEmail", this.getSale.token)
      this.$router.push("congrats")
    },
  },
}
</script>

<style lang="scss" scoped>
.page-payment {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 -8px;
  .card {
    padding-top: 12px;
    padding-bottom: 12px;
    .offers-list {
      margin: 0px -8px;
    }
    .payment-infos {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin: 16px -16px 0;
      .number input::placeholder {
        padding-left: 44px;
        background-image: url("https://res.cloudinary.com/caarea/image/upload/v1669309414/cs/sign/credit-card_ecqpik.svg");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 36px 25px;
      }
      .date-code {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 24px;
        .payment-date,
        .payment-code {
          text-align: center;
          &::placeholder {
            font-size: rem-calc(12);
          }
        }
        .payment-code {
          text-align: center;
        }
      }
    }
  }
}
</style>
