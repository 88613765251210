import Vue from "vue"
import VueI18n from "vue-i18n"
import numberFormats from "@/i18n/numberFormats.json"
import en from "./en"
import fr from "./fr"

const messages = {
  en: en,
  fr: fr,
}

Vue.use(VueI18n)

export default new VueI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages,
  numberFormats,
})
