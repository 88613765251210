<template>
  <div class="mainlayout">
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: "NoLayout",
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = this.$t(to.meta.title) || "Caarea Solutions"
      },
    },
  },
}
</script>

<style lang="scss" scoped>
main {
  text-align: center;
}
</style>
