<template>
  <div>
    <ModalSigningError
      v-if="showSigningErrorModal"
      data-cy="signing-error-modal"
      @close="onCloseSigningErrorModal"
    ></ModalSigningError>
    <YousignIframe
      :signature-link="signatureLink"
      :is-sandbox="isYousignEnvSandbox"
      @document-signed="onDocumentSigned"
      @signing-error="onSigningError"
      ref="yousignIframe"
    ></YousignIframe>
    <router-link class="button" :to="{ name: 'steps' }" :data-cy="buttonDataCy">
      {{ buttonText }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import YousignIframe from "@/components/YousignIframe"
import ModalSigningError from "@/components/modal/ModalSigningError"

export default {
  name: "Signature",
  components: { YousignIframe, ModalSigningError },
  data: function () {
    return {
      showSigningErrorModal: false,
    }
  },
  computed: {
    ...mapGetters("sale", ["getSignatureLink", "isContractSigned"]),
    isYousignEnvSandbox() {
      return process.env.VUE_APP_YOUSIGN_ENVIRONMENT === "sandbox"
    },
    buttonText() {
      return this.isContractSigned
        ? this.$t("signature.buttons.payment_information")
        : this.$t("signature.buttons.back")
    },
    buttonDataCy() {
      return this.isContractSigned ? "payment-info-button" : "back-button"
    },
    signatureLink() {
      // Bypass the domain verification in sandbox env
      return this.isYousignEnvSandbox
        ? `${this.getSignatureLink}&disable_domain_validation=true`
        : this.getSignatureLink
    },
  },
  methods: {
    async onDocumentSigned(signatureRequestId) {
      // Yousign actually sends signature success event everytime the Yousign iframe is displayed
      // and signature has already been done
      // => only call contracts creation API if contract creation has not been done yet
      if (!this.isContractSigned) {
        await this.$store.dispatch("sale/createContract", signatureRequestId)
      }
    },
    onSigningError() {
      this.showSigningErrorModal = true
    },
    onCloseSigningErrorModal() {
      this.showSigningErrorModal = false
    },
  },
  mounted() {
    // To enable a Cypress test to emit an event on YousignIframe component
    if (window.Cypress) {
      window.vueSignature = this
    }
  },
}
</script>

<style scoped lang="scss">
.button {
  margin: 24px auto 0 auto;
  display: block;
}
</style>
