<template>
  <div class="page-steps">
    <OffersList :offers="getOffers" :prices="false" />
    <div class="card" data-cy="card-text">
      <template v-if="!isContractSigned">{{ $t("steps.two_steps_left") }}</template>
      <template v-else>{{ $t("steps.missing_payment_information") }}</template>
    </div>
    <div class="action-buttons">
      <router-link
        class="button"
        :class="{ 'success icon': isContractSigned }"
        :to="{ name: 'signature' }"
        data-cy="signature-button"
      >
        <span
          v-if="isContractSigned"
          class="material-symbols-outlined"
          data-cy="check-icon-signature"
        >
          check
        </span>
        {{ $t("steps.electronic_signature") }}
      </router-link>
      <router-link
        class="button"
        :class="{ disabled: !isContractSigned }"
        :to="{ name: 'resume' }"
        data-cy="payment-info-button"
      >
        {{ $t("steps.payment_information") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OffersList from "@/components/OffersList"

export default {
  name: "Steps",
  components: {
    OffersList,
  },
  computed: {
    ...mapGetters("offers", ["getOffers"]),
    ...mapGetters("sale", ["isContractSigned"]),
  },
}
</script>

<style lang="scss" scoped>
.page-steps {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
</style>
