import login from "./views/login.json"
import home from "./views/home.json"
import offers from "./views/offers.json"
import terms from "./views/terms.json"
import steps from "./views/steps.json"
import code from "./views/code.json"
import resume from "./views/resume.json"
import payment from "./views/payment.json"
import congrats from "./views/congrats.json"
import error from "./views/error.json"
import spinner from "./spinner.json"
import caareavlib from "../../../node_modules/caarea-vlib/src/i18n/fr"
import signature from "./views/signature.json"

export default {
  login,
  home,
  offers,
  terms,
  steps,
  code,
  resume,
  payment,
  congrats,
  error,
  spinner,
  signature,
  caareavlib,
}
