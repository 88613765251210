// STATES (snake_case)
const state = {
  offers: [
    {
      image:
        "https://res.cloudinary.com/caarea/image/upload/v1606814487/cs/products/vehicles/new_otanym.jpg",
      name: "Best car",
      details: "Extension de garantie",
      duration: "24 mois",
      monthly: true,
    },
    {
      image:
        "https://res.cloudinary.com/caarea/image/upload/v1606814763/cs/products/vehicles/occaz_w3xap2.jpg",
      name: "Road for ever",
      details: "Extension de garantie",
      duration: "24 mois",
      price: 7,
      monthly: true,
    },
    {
      image:
        "https://res.cloudinary.com/caarea/image/upload/v1606814770/cs/products/vehicles/batterie-elec_aizyy6.jpg",
      name: "Easy battery",
      details: "Garantie performance de batterie",
      duration: "24 mois",
      price: 789,
    },
  ],
}

// GETTERS (camelCase)
const getters = {
  getOffers: (state) => state.offers,
}

export default {
  namespaced: true,
  state,
  getters,
}
