<template>
  <div class="page-terms">
    <div class="action-buttons">
      <router-link class="button terms-accept" :to="{ name: 'steps' }">
        {{ $t("offers.terms_modal.accept_the_terms") }}
      </router-link>
      <router-link class="terms-refuse" :to="{ name: 'offers' }">
        {{ $t("offers.terms_modal.refuse_the_terms") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",
}
</script>

<style lang="scss" scoped>
.page-terms {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
