import Vue from "vue"
import VueRouter from "vue-router"
import CypressAuth from "@/views/CypressAuth"
import NoLayout from "@/layouts/NoLayout"
import LayoutHome from "@/layouts/LayoutHome"
import Layout from "@/layouts/Layout"
import Home from "@/views/Home"
import Offers from "@/views/Offers"
import Terms from "@/views/Terms"
import Steps from "@/views/Steps"
import Resume from "@/views/Resume"
import Payment from "@/views/Payment"
import Congrats from "@/views/Congrats"
import guards from "./guards"
import Page403 from "@/views/errors/Page403"
import Page404 from "@/views/errors/Page404"
import Page500 from "@/views/errors/Page500"
import Signature from "@/views/Signature"

Vue.use(VueRouter)

const routes = [
  {
    path: "/:lang",
    name: "lang",
    beforeEnter: guards.beforeEnter,
    redirect: { name: "privateZone" },
    component: {
      render(c) {
        return c("router-view")
      },
    },
    children: [
      {
        path: ":token",
        name: "privateZone",
        beforeEnter: guards.requireAccess,
        component: LayoutHome,
        redirect: { name: "home" },
        children: [
          {
            path: "",
            name: "home",
            component: Home,
          },
          {
            path: "",
            name: "layout",
            component: Layout,
            children: [
              {
                path: "offers",
                name: "offers",
                component: Offers,
              },
              {
                path: "terms",
                name: "terms",
                component: Terms,
              },
              {
                path: "steps",
                name: "steps",
                component: Steps,
              },
              {
                path: "signature",
                name: "signature",
                component: Signature,
              },
              {
                path: "resume",
                name: "resume",
                component: Resume,
              },
              {
                path: "payment",
                name: "payment",
                component: Payment,
              },
              {
                path: "congrats",
                name: "congrats",
                component: Congrats,
              },
            ],
          },
        ],
      },
      {
        path: "error",
        redirect: { name: "404" },
        name: "error",
        component: NoLayout,
        children: [
          {
            path: "403",
            name: "403",
            component: Page403,
          },
          {
            path: "404/:message?",
            name: "404",
            component: Page404,
            props: true,
          },
          {
            path: "500",
            name: "500",
            component: Page500,
          },
        ],
      },
      {
        path: "*",
        beforeEnter: guards.beforeEnter,
      },
    ],
  },
  {
    path: "*",
    beforeEnter: guards.beforeEnter,
  },
]

if (window.Cypress) {
  // add route for testing purpose
  routes[0].children[0].children.push({
    path: "cypress",
    name: "cypress",
    component: CypressAuth,
  })
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
