<template>
  <Modal @close="onClose">
    <template #title>
      {{ $t("signature.signing_error_modal.title") }}
    </template>
    <template #body>
      <div class="modal-body">
        <p class="modal-body__description" data-cy="signing-error-modal-description">
          {{ $t("signature.signing_error_modal.description") }}
        </p>
        <button @click="onClose" data-cy="signing-error-modal-ok-button">
          {{ $t("signature.signing_error_modal.ok_button") }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal"

export default {
  name: "ModalSigningError",
  components: {
    Modal,
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-body {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
}

.modal-body__description {
  white-space: pre;
}
</style>
