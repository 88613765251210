<template>
  <div>
    <h1>403</h1>
    <b>{{ $t("error.403") }}</b>
    <p>{{ $t("error.continue") }}</p>
    <button data-cy="home-btn" @click.prevent="pushI18nRoute({ name: getUrlName })">
      {{ $t("error.home") }}
    </button>
  </div>
</template>

<script>
import ErrorPageMixin from "@/mixins/ErrorPageMixin"

export default {
  name: "Page403",
  mixins: [ErrorPageMixin],
}
</script>
