<template>
  <div class="offers-list">
    <div
      class="offer"
      :class="{ 'with-price': prices }"
      v-for="offer in offers"
      :key="offer.name"
    >
      <div class="offer-image">
        <img :src="offer.image" alt="" />
      </div>
      <div class="offer-infos">
        <div class="offer-name">{{ offer.name }}</div>
        <div class="offer-details">{{ offer.details }}</div>
        <div class="offer-duration">{{ offer.duration }}</div>
      </div>
      <div v-if="prices" class="offer-price-frequency">
        <div class="offer-price">
          <template v-if="offer.price">{{ offer.price }} €</template>
          <template v-else>{{ $t("offers.included") }}</template>
        </div>
        <div v-if="offer.monthly" class="offer-frequency">par mois</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OffersList",
  props: {
    offers: {
      type: Array,
    },
    prices: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.offers-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: initial;
  .offer {
    display: grid;
    grid-template-columns: 56px auto;
    gap: 16px;
    &.with-price {
      grid-template-columns: 56px 2fr 1fr;
    }
    .card & {
      gap: 8px;
      padding-bottom: 16px;
      border-bottom: 1px solid $caareagray;
      &:last-child {
        padding: 0;
        border: initial;
      }
    }
    &-image {
      width: 56px;
      height: 56px;
      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        box-shadow: $shadow;
        .card & {
          box-shadow: initial;
        }
      }
    }
    &-infos {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    &-name {
      font-family: $font-family-base;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.09rem;
    }
    &-details,
    &-duration {
      font-family: $font-family-alt;
      font-weight: 300;
      font-size: rem-calc(12);
      line-height: 14px;
      letter-spacing: 0.09em;
    }
    &-price-frequency {
      justify-self: flex-end;
      align-self: center;
      text-align: right;
    }
    &-price {
      font-family: $font-family-base;
      font-weight: 500;
      font-size: rem-calc(24);
      line-height: 29px;
      letter-spacing: 0.05rem;
    }
    &-frequency {
      font-family: $font-family-alt;
      font-weight: 300;
      font-size: rem-calc(12);
      line-height: 14px;
      letter-spacing: 0.09rem;
    }
  }
}
</style>
