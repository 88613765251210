<template>
  <div data-cy="yousign-iframe" id="iframe-container"></div>
</template>

<script>
export default {
  name: "YousignIframe",
  props: {
    signatureLink: { type: String, required: true },
    isSandbox: { type: Boolean, required: true },
  },
  mounted() {
    // This if condition is necessary to avoid calling Yousign api during tests !
    if (!window.Cypress) {
      const yousign = new Yousign({
        signatureLink: this.signatureLink,
        iframeContainerId: "iframe-container",
        isSandbox: this.isSandbox,
      })

      yousign.onSuccess((data) => {
        console.log("Signer has successfully signed")
        this.$emit("document-signed", data.signature_request_id)
      })

      yousign.onError((data) => {
        console.log("Signer encountered an error when signing")
        this.$emit("signing-error")
      })
    }
  },
}
</script>

<style scoped></style>
